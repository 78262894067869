// Imports => React
import React, {
	useEffect,
	useState,
	useMemo,
	useRef,
	forwardRef,
	useImperativeHandle,
} from 'react';
import clsx from 'clsx';
import GoogleMapReact from 'google-map-react';
import usePlacesAutocomplete, {
	getGeocode,
	getLatLng,
} from 'use-places-autocomplete';

// Imports => Config
import config from '@config';

// Imports => Constants
import {
	ICONS,
	KEYS,
	MAP_CLUSTER_OPTIONS,
	MAP_OPTIONS,
	SIZES,
	THEMES,
	VISUALS,
} from '@constants';

// Imports => Utilties
import {
	AcIsSet,
	AcIsNull,
	AcGetMapMarkerImage,
	AcGenerateMapMarkerElement,
} from '@utils';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-edit-location-map-widget',
	MAP: 'ac-edit-location-map-widget__map',
	OVERLAY: 'ac-edit-location-map-widget__overlay',
};

const AcEditLocationMapWidget = ({ location, callback }) => {
	const [position, setPosition] = useState(location);
	const [marker, setMarker] = useState(null);
	const [mapInstance, setMapInstance] = useState(null);
	const mapRef = useRef(null);
	const markerRef = useRef(null);

	const {
		ready,
		value,
		suggestions: { status, data },
		setValue,
		clearSuggestions,
	} = usePlacesAutocomplete({
		requestOptions: {
			/* Define search scope here */
		},
		debounce: 300,
	});

	const handleCallback = (event, loc) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		if (callback) callback(loc);
	};

	const handleMapLocationSelect = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		const { lat, lng } = event;
		const loc = { lat, lng };
		setPosition({ lat, lng });

		const { maps } = mapInstance;
		const pos = new maps.LatLng(lat, lng);
		if (markerRef?.current?.position) {
			markerRef.current.position = pos;
			handlePanTo(pos);
		}
		handleCallback(null, loc);
		setValue('');
	};

	const handleMapClick = (event) => {
		if (event && event.persist) event.persist();
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.stopPropagation) event.stopPropagation();

		const latitude = event.latLng.lat();
		const longitude = event.latLng.lng();
		const loc = { lat: latitude, lng: longitude };

		setPosition(loc);

		const { maps } = mapRef?.current;
		const pos = new maps.LatLng(latitude, longitude);

		if (markerRef?.current?.position) {
			markerRef.current.position = pos;
			handlePanTo(pos);
		}
		handleCallback(null, loc);
		setValue('');
	};

	const handlePanTo = (loc) => {
		const { map } = mapRef.current;

		map.panTo(loc);
	};

	const renderProjectMarker = (location, map, maps) => {
		const url = AcGetMapMarkerImage(KEYS.PROJECTS);

		// Marker image
		// original size: 268 x  352

		const iconElement = AcGenerateMapMarkerElement(url, 32, 42, -16, -40.5);
		const pos = new maps.LatLng(location.lat, location.lng);

		return new maps.marker.AdvancedMarkerElement({
			position: pos,
			content: iconElement,
			map,
		});
	};

	const init = ({ map, maps }) => {
		if (!AcIsSet(position)) return;

		setMapInstance({ maps, map });
		mapRef.current = { maps, map };

		const projectMarker = renderProjectMarker(position, map, maps);
		setMarker(projectMarker);
		markerRef.current = projectMarker;

		const center = new maps.LatLng(position.lat, position.lng);

		map.setCenter(center);

		maps.event.addListener(map, 'click', handleMapClick);
	};

	const renderMapWidget = useMemo(() => {
		if (!position) return null;

		const { maps_key: key } = config;
		return (
			<GoogleMapReact
				{...MAP_OPTIONS}
				yesIWantToUseGoogleMapApiInternals
				bootstrapURLKeys={{
					key,
					libraries: ['places', 'marker'],
					language: 'en',
					region: 'en',
				}}
				onGoogleApiLoaded={init}
			/>
		);
	}, [init, position]);

	const handleInputChange = (event) => {
		// Update the keyword of the input element
		setValue(event.target.value);
	};

	const handleSelect = ({ description }) => {
		// When user selects a place, we can replace the keyword without request data from API
		// by setting the second parameter as "false"
		setValue(description, false);
		clearSuggestions();

		// Get latitude and longitude via utility functions
		getGeocode({ address: description })
			.then((results) => getLatLng(results[0]))
			.then(({ lat, lng }) => {
				handleMapLocationSelect({ lat, lng });
			})
			.catch((error) => {});
	};

	const renderSuggestions = useMemo(() => {
		if (!status || status !== 'OK') return null;

		const collection = data;
		const len = collection.length;
		let n = 0;
		let result = [];

		for (n; n < len; n++) {
			const suggestion = collection[n];

			const {
				id,
				structured_formatting: { main_text, secondary_text },
			} = suggestion;

			const object = (
				<li
					key={`${main_text}-${secondary_text}`}
					onClick={() => handleSelect(suggestion)}
				>
					<strong>{main_text}</strong> <small>{secondary_text}</small>
				</li>
			);

			result.push(object);
		}

		return <ul>{result}</ul>;
	}, [status, data, handleSelect]);

	const getOverlayClassNames = useMemo(() => {
		return clsx(_CLASSES.OVERLAY);
	}, []);

	const getMapClassNames = useMemo(() => {
		return clsx(_CLASSES.MAP);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	return (
		<div className={getMainClassNames}>
			<div className={getMapClassNames}>{renderMapWidget}</div>
			<div className={getOverlayClassNames}>
				<input
					value={value}
					onChange={handleInputChange}
					disabled={!ready}
					placeholder={'Find a location (eg. by country, city, postal code)'}
				/>
				<AcIcon icon={ICONS.MAGNIFY} />
				{renderSuggestions}
			</div>
		</div>
	);
};

export default AcEditLocationMapWidget;
