import React, { useState, useCallback, useMemo, memo } from 'react';
import { Link } from 'react-router-dom';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, TYPES } from '@constants';

// Imports => Utilities
import {
  AcUUID,
  AcIsSet,
  AcIsArray,
  AcGetEquipmentIcon,
  AcFormatInternalURI,
} from '@utils';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcCard = loadable(() => import('@atoms/ac-card/ac-card.web'));
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));
const AcImage = loadable(() => import('@atoms/ac-image/ac-image.web'));

const _CLASSES = {
  MAIN: 'ac-equipment-card',
  VISUAL: {
    WRP: 'ac-equipment-card__visual-wrp',
    MAIN: 'ac-equipment-card__visual',
    IMAGE: 'ac-equipment-card__visual__image',
    RENDERED: 'ac-equipment-card__visual--rendered',
  },
  HEADER: 'ac-equipment-card__header',
  TITLE: 'ac-equipment-card__title',
  SUBTITLE: 'ac-equipment-card__subtitle',
  BODY: 'ac-equipment-card__body',
  ROW: 'ac-equipment-card__row',
  LABEL: 'ac-equipment-card__label',
  VALUE: 'ac-equipment-card__value',
};

const AcEquipmentCard = ({
  id,
  equipment_type,
  image,
  title,
  subtitle,
  lines,
}) => {
  const [imageRendered, setImageRendered] = useState(false);

  const getValueClassNames = useMemo(() => {
    return clsx(_CLASSES.VALUE);
  }, []);

  const getLabelClassNames = useMemo(() => {
    return clsx(_CLASSES.LABEL);
  }, []);

  const getRowClassNames = useMemo(() => {
    return clsx(_CLASSES.ROW);
  }, []);

  const getBodyClassNames = useMemo(() => {
    return clsx(_CLASSES.BODY);
  }, []);

  const getSubtitleClassNames = useMemo(() => {
    return clsx(_CLASSES.SUBTITLE);
  }, [subtitle]);

  const getTitleClassNames = useMemo(() => {
    return clsx(_CLASSES.TITLE);
  }, [title]);

  const getHeaderClassNames = useMemo(() => {
    return clsx(_CLASSES.HEADER);
  });

  const getImageClassNames = useMemo(() => {
    return clsx(_CLASSES.VISUAL.IMAGE);
  });

  const getVisualClassNames = useMemo(() => {
    return clsx([
      _CLASSES.VISUAL.MAIN,
      imageRendered && _CLASSES.VISUAL.RENDERED,
    ]);
  }, [imageRendered]);

  const getVisualWrpClassNames = useMemo(() => {
    return clsx(_CLASSES.VISUAL.WRP);
  }, []);

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN);
  }, []);

  const renderDetails = useMemo(() => {
    const collection = lines;
    const len = collection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const item = collection[n];

      const obj = (
        <div
          className={getRowClassNames}
          key={`equipment-card-line-${item?.key}`}
        >
          <div
            className={getLabelClassNames}
            dangerouslySetInnerHTML={{
              __html: item?.key,
            }}
          />
          <div
            className={getValueClassNames}
            dangerouslySetInnerHTML={{
              __html: item?.value,
            }}
          />
        </div>
      );

      result.push(obj);
    }

    return result;
  }, [lines]);

  const renderSubtitle = useMemo(() => {
    return (
      <AcHeading rank={4} className={getSubtitleClassNames}>
        {subtitle}
      </AcHeading>
    );
  }, [subtitle]);

  const renderTitle = useMemo(() => {
    return (
      <AcHeading rank={3} className={getTitleClassNames}>
        {title}
      </AcHeading>
    );
  }, [title]);

  const renderVisual = useMemo(() => {
    const icon = AcGetEquipmentIcon(equipment_type?.group);

    return (
      <div className={getVisualWrpClassNames}>
        <div className={getVisualClassNames}>
          {AcIsSet(image) && (
            <AcImage
              source={image}
              callback={(state) => setImageRendered(state)}
            />
          )}
          {(!AcIsSet(image) || !imageRendered) && <AcIcon icon={icon} />}
        </div>
      </div>
    );
  }, [image, equipment_type, imageRendered]);

  const getDetailRoute = useMemo(() => {
    const route = AcFormatInternalURI(
      { id, entity: equipment_type?.group },
      'View details'
    );

    return route;
  }, [id, equipment_type]);

  return (
    <AcCard className={getMainClassNames}>
      <Link to={getDetailRoute}>
        {renderVisual}

        <header className={getHeaderClassNames}>
          {renderTitle}
          {renderSubtitle}
        </header>

        {lines?.length !== 0 && (
          <section className={getBodyClassNames}>{renderDetails}</section>
        )}
      </Link>
    </AcCard>
  );
};

export default memo(AcEquipmentCard);
